<template>
    <div class="step_checkbox_layout">
        <div class="step_sub_title">
            Please upload your address verification 请上传您的地址认证
        </div>
        <a-form-model-item :prop="radio_var">
            <a-radio-group
                :disabled='disabled'
                class="modify_antd_radio"
                :name="radio_var"
                :value="address_prove_type"
                @change="handleRadio"
            >
                <a-radio :style="radioStyle" value="bank_statements">
                    Bank Statements and Bank Correspondence 银行对账单和银行通信
                </a-radio>
                <a-radio :style="radioStyle" value="utility_bills">
                    Utility Bill 各种账单
                </a-radio>
                <a-radio :style="radioStyle" value="insurance_kiwisaver">
                    superannuation account 保险
                </a-radio>
                <a-radio :style="radioStyle" value="government_correspondence">
                    Government Agency Correspondence (Council Rates, etc.)
                    政府机构通信</a-radio
                >
                <a-radio
                    :style="radioStyle"
                    value="electroral_commission_correspindence"
                >
                    Electoral Commission Correspondence 选举委员会函件</a-radio
                >
                <a-radio :style="radioStyle" value="tenancy_agreement">
                    Tenancy Agreement 租赁协议</a-radio
                >
                <a-radio :style="radioStyle" value="vehicle_registration">
                    Vehicle Registration 车辆登记</a-radio
                >
                <a-radio :style="radioStyle" value="insitution_letter">
                    Letter from Australia Educational institution
                    澳大利亚教育机构的来信</a-radio
                >
            </a-radio-group>
        </a-form-model-item>
        <div class="step_checkbox_layout">
            <div v-show="address_prove_type == 'bank_statements'">
                <ul class="step_ul_style">
                    <li>
                        This must be a statement from a bank/IRD that has been
                        posted to the client and is dated within the last 3
                        months. 这必须是银行/
                        IRD的记录，该记录信件必须是最近三个月内通过信件发送给注册人的。
                    </li>
                    <li>
                        Bank correspondence must confirm a business relationship
                        exists between the bank and the client.
                        银行通信必须是能确认银行与注册人之间存在业务关系的
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'utility_bills'">
                <ul class="step_ul_style">
                    <li>
                        This must be a statement for utility service at a fixed
                        location that has been posted to the client and is dated
                        within the last 3 months.
                        这必须最近三个月内通过邮寄发送去注册人的账单。
                    </li>
                    <li>
                        Fixed services include electricity, water, gas, home
                        internet/landlines and Sky.
                        服务账单包括电力，水，煤气，家庭互联网/固定电话和电视。
                    </li>
                    <li>
                        Fixed services do not include mobile phones.
                        服务账单不包括手机账单
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'insurance_kiwisaver'">
                <ul class="step_ul_style">
                    <li>
                        This must be a statement or correspondence from a
                        current insurance company or KiwiSaver Provider that has
                        been posted to the client and is dated within the last 3
                        months. 这必须是三个月内来自真实存在的保险公司 或者
                        KiwiSaver 提供商通过邮寄发送至您住址的账单或者是通信
                    </li>
                    <li>
                        This includes confirmation of bond lodgements with the
                        Department of Building and Housing.
                        这可以是来自于建筑和住房部押金投管确认信
                    </li>
                    <li>
                        This includes confirmation of bond lodgements with the
                        Department of Building and Housing.
                        这可以是来自于建筑和住房部押金投管确认信
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'government_correspondence'">
                <ul class="step_ul_style">
                    <li>
                        This must be a statement, letter or invoice that has
                        been posted to the client from a government agency and
                        is dated within the last 3 months.
                        这必须最近三个月内来自政府机构通过邮寄发送去注册人的声明/信件/发票。
                    </li>
                    <li>
                        This includes confirmation of bond lodgements with the
                        Department of Building and Housing.
                        这可以是来自于建筑和住房部押金投管确认信
                    </li>
                </ul>
            </div>
            <div
                v-show="
                    address_prove_type == 'electroral_commission_correspindence'
                "
            >
                <ul class="step_ul_style">
                    <li>
                        This must be a letter that has been posted to the client
                        and is dated within the last 3 months.
                        这必须是三个月内通过邮寄发送至注册人的信件
                    </li>
                    <li>
                        unless it is JP/lawyer certified. . 除非获得JP
                        /律师认证，否则我们不接受来自在线帐户或电子邮件通信的打印信息。
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'tenancy_agreement'">
                <ul class="step_ul_style">
                    <li>
                        This must be a signed tenancy agreement dated within the
                        last 3 months. 这必须是过去3个月内签署的租赁协议
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'vehicle_registration'">
                <ul class="step_ul_style">
                    <li>
                        This must be a current vehicle registration that has
                        been posted to the client and is dated within the last 3
                        months.
                        这必须是三个月内通过邮寄发送给注册人的车辆登记信件
                    </li>
                </ul>
            </div>
            <div v-show="address_prove_type == 'insitution_letter'">
                <ul class="step_ul_style">
                    <li>
                        This must be letterhead paper and signed by person in
                        authority confirming residential address and is dated
                        within the last 3 months.
                        这必须是信头纸，并由确认住址的人员签字，并注明日期在过去3个月内
                    </li>
                    <li>
                        This may also include a fees invoice or receipt from the
                        institution that has been posted to the client and is
                        dated within the last 3 months.
                        这可能还包括在三个月内已邮寄给注册人的的机构费用发票或收据。
                    </li>
                </ul>
            </div>
            <div v-if="address_prove_type" class="step_upload_box marginT_10">
                <AUploadFile
                    @FileValidate="FileValidate"
                    title=""
                    :disabled='disabled'
                    :prop="address_prove_file_list_str"
                    :fileList="address_prove_file_list"
                    @handleFileChange="handleFileList"
                />
            </div>
        </div>
    </div>
</template>

<script>
import AUploadFile from '@/components/AUploadFile'

export default {
  created () {
  },
  components: {
    AUploadFile
  },
  computed: {
  },
  props: {
    disabled: {
      type: Boolean,
      default: false
    },
    radio_var: {
      type: String,
      default: ''
    },
    address_prove_type: {
      type: String
    },
    address_prove_file_list: {
      type: Array
    },
    address_prove_file_list_str: {
      type: String
    }
  },
  data () {
    return {
      radioStyle: {
        lineHeight: '30px',
        width: '100%',
        marginBottom: '10px'
      }
    }
  },
  methods: {
    FileValidate () {
      const variable = this.address_prove_file_list_str
      this.$emit('FileValidate', variable)
    },
    handleRadio (e) {
      const { name, value } = e.target
      this.$emit('handleChange', name, value)
      // this.$emit('handleChange', this.address_prove_file_list_str, [])
    },
    handleFileList (e) {
      const variable = this.address_prove_file_list_str
      this.$emit('handleChange', variable, e)
    }
  }
}
</script>

<style scoped>
.step_checkbox_layout {
    margin-top: 20px;
}
.step_sub_title {
    margin: 20px 0;
    font-size: 15px;
    color: rgba(0, 0, 0, 0.65);
    font-weight: 500;
}
.step_ul_style li::before {
    content: '\2022';
    color: #999;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1rem;
}
.step_ul_style .li_require::before {
    content: '\2022';
    color: red;
    font-weight: bold;
    display: inline-block;
    width: 1em;
    margin-left: -1rem;
}
.marginT_10 {
    margin-top: 10px;
}
.modify_antd_radio >>> .ant-radio-inner {
    width: 26px;
    height: 26px;
}
.modify_antd_radio >>> .ant-radio-wrapper {
    white-space: pre-wrap;
    display: flex;
}
.modify_antd_radio >>> .ant-radio-inner::after {
    width: 12px;
    left: 6px;
    top: 6px;
    height: 12px;
}
.modify_antd_radio >>> .ant-radio-checked::after {
    height: 25px;
}
</style>
