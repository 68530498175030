<template>
      <a-form-model-item
            :required="required"
            :label="label"
            :prop="prop"
          >
      <a-select
        :disabled="disabled"
        :value="selectedVal"
        placeholder="输入搜索内容"
        style="width: 100%;"
        show-search
        option-filter-prop="children"
        @search="handleSearch"
        @popupScroll="handlePopupScroll"
        @change="handleSelect"
      >
      <a-select-opt-group label="Popular Countries">
                <a-select-option
                  v-for="item of popularList"
                  v-bind:key="item.code"
                  :value="item[valType]"
                >
                  {{ item.name + ' ' + item.dial_code }}
                </a-select-option>
        </a-select-opt-group>
        <a-select-opt-group label="All Countries">
        <a-select-option v-for="item of renderedOptions" :value="item[valType]" :key="item.name">
            {{ item.name + ' ' + item.dial_code }}
        </a-select-option>
        </a-select-opt-group>
      </a-select>
      </a-form-model-item>
</template>

<script>
import popularCountryList from '@/assets/tax-country-popluar.json'
import otherCountry from '@/assets/old.json'
import debounce from 'lodash/debounce'
const LOAD_NUM = 6

export default {
  props: {
    label: {
      type: String,
      default: ''
    },
    required: {
      type: Boolean,
      default: false
    },
    prop: {
      type: String,
      default: ''
    },
    selectedVal: {
      type: String,
      default: null
    },
    valType: {
      type: String,
      default: 'code'
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  created () {
    this.popularList = popularCountryList
  },

  data () {
    return {
      oriDataList: [], // 原数据列表 -- 从接口获取
      dataLoading: false, // 原数据列表的加载状态 -- 接口的响应状态
      searchVal: '', // 搜索的内容
      filterDataList: [], // 过滤的数据列表 -- 从dataList中过滤出含搜索内容的数据
      renderedOptions: [] // 已渲染的下拉列表
    }
  },
  mounted () {
    this.getDataList()
  },

  methods: {
    getDataList () {
      this.oriDataList = otherCountry
      this.renderedOptions = this.oriDataList.slice(0, 1)
    },
    handleSearch (val) {
      this.searchVal = val.slice(0, 1).toUpperCase() + val.slice(1).toLowerCase()
      let filterList = []
      if (val) {
        filterList = this.oriDataList.filter(item => item.name.includes(val))
      } else {
        filterList = this.oriDataList
      }
      this.filterDataList = filterList
      this.renderedOptions = filterList.length < LOAD_NUM ? filterList : filterList.slice(0, LOAD_NUM)
    },
    // 滚动时触发（防止抖动）
    handlePopupScroll: debounce(function () {
      if (this.searchVal === '') {
        this.loadMoreData(this.oriDataList)
      } else {
        this.loadMoreData(this.filterDataList)
      }
    }, 400),
    // 加载更多数据到select框
    loadMoreData (dataList) {
      const renderedLen = this.renderedOptions.length // 已渲染的下拉列表长度
      const totalLen = dataList.length // 当前数据源的长度
      let addList = []
      if (renderedLen < totalLen) {
        if (renderedLen + LOAD_NUM <= totalLen) {
          addList = dataList.slice(renderedLen, renderedLen + LOAD_NUM)
        } else {
          addList = dataList.slice(renderedLen, renderedLen + (totalLen % LOAD_NUM))
        }
        this.renderedOptions = (this.renderedOptions).concat(addList)
      }
    },
    // 被选中时调用，参数为选中项的 value (或 key) 值
    handleSelect (val) {
      if (this.searchVal) {
        const selectedArr = (this.oriDataList).filter(item => item === val) // 从数据源中过滤出下拉框选中的值，并返回一个数组
        const restList = (this.oriDataList).filter(item => item !== val) // 从数据源中过滤出其他的值，返回一个数组
        const newList = selectedArr.concat(restList).slice(0, LOAD_NUM) // 将选中的元素放到下拉列表的第一位
        this.renderedOptions = newList // 更新已渲染的下拉列表
        this.oriDataList = selectedArr.concat(restList) // 更新数据源
        this.searchVal = '' // 因为触发handleSelect函数时，会自动清空用户输入的内容。因此，searchVal需要重置。
      }
      this.$emit('handleChange', this.prop, val)
      this.renderedOptions = this.oriDataList.slice(0, 1)
    }
  }
}
</script>
<style>
</style>
